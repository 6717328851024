.headerInner{
    width: 100%;

    video{
        width: 100%;
        height: 700px;
        object-fit: cover;
        position: relative;        
    }

    &__img{
        content: '';
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 80px;
        display: flex;
        flex-direction: column;

        .headerInner__text{
            width: 100%;
            display: flex;
            height: 600px;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;

            &__top{
                color: #fff;
                font-size: 50px;
                text-transform: uppercase;
                padding: 30px 0;
                text-shadow: 2px 2px 8px #000000;
                width: 50%;
                min-height: 180px;
            }

            &__bottom{
                color: #fff;
                font-size: 20px;
                font-weight: 400;
                text-shadow: 2px 2px 8px #000000;
                
            }
        }
    }
    &__under{
        width: 100%;
        height: 80px;
        background-image: rgb(219, 219, 219);

        &__text{
            width: 100%;
            color: rgb(46, 46, 46);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h2{
                font-size: 25px;
                font-weight: 300;
            }
            p{
                font-weight: 500;
                font-size: 1.2rem;
            }
        }
    }
}