.header__nav{
    width: 100%;
    min-height: 70px;
    background-color: transparent;
    display: flex;
    align-items: center;
    color: #fff;
    border-bottom: solid 2px #fff;

    .navbar{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .logo_small{
            max-width: 150px;
        }
        .navbar__links{
        
            ul{
                display: flex;
                justify-content: space-between;
                list-style: none;
                padding: 0;
                margin: 0;
    
                li{
                    margin: 0 10px;
                    a{
                        text-decoration: none;
                        color: #fff;
                        
                        p{
                            margin: 0;
                            padding: 0;
                            font-size: 1.15rem;
                            font-weight: 500;
                            position: relative;
                        }
                        p::before{
                            content: '';
                            bottom: 0;
                            left: 0;
                            position: absolute;
                            width: 0%;
                            height: 2px;
                            background-color: #fff;
                            transition: 0.4s;
                        }
                        p:hover::before{
                            width: 100%;
                        }
                    }
                }
            }
        }

        .hamburgher__button{
            position: absolute;
            top: 1rem;
            right: 1rem;
            display: none;
            flex-direction: column;
            justify-content: space-between;
            width: 30px;
            height: 21px;

            .bar{
                height: 3px;
                width: 100%;
                background-color: #fff;
                border-radius: 10px;
            }
        }
    }
    @media (max-width: 900px){
        .navbar{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .logo_small{
                padding-bottom: 20px;
                padding-top: 10px;
            }

            .hamburgher__button{
                display: flex;
            }
            .navbar__links{
                display: none;
            }

            .navbar__links__media{
                width: 100%;
                display: flex;
                ul{
                    width: 100%;
                    flex-direction: column;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        width: 100%;
                        padding: 20px 0;
                        justify-content: center;
                        padding: 0;
                        margin: 0;
                        a {
                            color: #fff;
                            text-decoration: none;
                            p{
                                font-size: 20px;
                                font-weight: 400;
                                padding: 20px 0;
                                margin: 0;
                                position: relative;
                                width: 30%;
                            }
                            p::before{
                                content: '';
                                bottom: 0;
                                left: 0;
                                position: absolute;
                                width: 0%;
                                height: 2px;
                                background-color: #fff;
                                transition: 0.4s;
                            }
                            p:hover::before{
                                width: 100%;
                            }
                        }
                        
                    }
                }
                .active{
                    display: flex;
                }
            }
        }
    }
    @media (min-width: 900px) {
        .navbar__links__media{
            display: none;
        }
    }
}
