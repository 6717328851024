.cards{
    background-size: cover;
    width: 100%;
    background-image: url(../../img/backgraound_telefonia.jpg);
    background-attachment: fixed;
    padding-bottom: 40px;

    h2{
        padding: 30px 0;
        color: rgb(27, 27, 27);

        .secected__text{
        color: rgb(68, 68, 68);
        }
    }
    
    .cards__content{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

}


.cookies{
    content: '';
    position: fixed;
    width: 100%;
    background-color: rgb(44, 115, 248);
    z-index: 9;
    bottom: 0;
    
    .cookies__content{
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        
        h3{
            color: #fff;
            font-size: 15px;
        }

        button{
            padding: 10px;
            width: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            background-color: transparent;
            color: #fff;
            border-color: #fff;
        
        }
        button:hover{
            transition: .3s;
            border-color: transparent;
            background-color: #fff;
            color: rgb(55, 101, 255);
        }
    }
}
.hidden{
    display: none;
}