.footer{
    width: 100%;
    height: 100px;
    background-color: rgb(34, 34, 34);
    
    .footer__content{
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    p{
        color: #fff;
        padding: 0;
        font-size: 0.8rem;
        margin: 0;
    }
    a{
        padding: 0;
        margin: 0 1rem;
        font-size: 0.9rem;
        color: #fff;
        text-decoration: none;
    }
    a:hover{
        color: blue;
    }
}