.HederInner{
    width: 100%;
    height: auto;
    position: relative;
}

.Navbar{
    width: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
}