.contacts__page{
    background-color: rgb(238, 238, 238);

    .contacts__title{
        padding: 20px 0;
    }
}

.contacts__page__content{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;

    &__form{

        width: calc(50% - 10px);
        background-color: rgb(255, 255, 255);
        padding: 5px 20px 20px 20px;
        border-radius: 30px;
        animation: form-animation 1.5s;

        button{
            width: 100px;
            height: 40px;
            margin-top: 20px;
            background-color: transparent;
            border: solid 2px rgb(54, 158, 255);
            border-radius: 15px;
            color: rgb(54, 158, 255);
            font-size: 14px;
            font-weight: 700;   
        }
        button:hover{
            transition: 0.3s;
            background-color: rgb(54, 158, 255);
            border: solid 2px #fff;
            color: #fff;
        }

    
        .form__mail{
            width: 100%;
            height: 550px;
            display: flex;
            flex-direction: column;

            .possition__leotech__info{
        
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
        
                h2{
                    font-size: 24px;
                    }
                p{
                    font-size: 18px;
                }
            }
            .social{
                width: 100%;
                h2{
                    font-size: 24px;
                }

                &-main{
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    a{
                        font-size: 18px;
                        text-decoration: none;
                        margin: 0.5rem 0;
                        color: rgb(30, 135, 255);
                    }
                    a:hover{
                        transition: 0.3s;
                        color: gray;
                    }
                }
            }
        }
    }

    .possition__leotech{
        width: calc(50% - 10px);
        background-color: rgb(255, 255, 255);
        padding: 10px 20px 20px 20px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: informazioni-animation 1.5s;

        .possition__leotech__map{
            width: 100%;
            height: auto;

            iframe{
                width: 100%;
                height: 600px;
            }
        }
    }
        
}

@media (max-width: 1050px) {
    .contacts__page__content{
        flex-direction: column;

        .contacts__page__content__form{
            width: 100%;
            background-color: rgb(255, 255, 255);
            margin-bottom: 20px;
            padding: 25px;
        }


        .possition__leotech{
            width: 100%;
            background-color: rgb(255, 255, 255);
            margin-bottom: 20px;
            padding: 25px;
        }
    }
}

@keyframes form-animation {
    0%{
        transform: translate(-2000px);
        scale: 1;
    }
}
@keyframes informazioni-animation {
    0%{
        transform: translate(2000px);
        scale: 1;
    }
}
